<template lang="pug">
  v-tooltip(bottom max-width="700")
    template(v-slot:activator="{ on }")
      div.paid-module(v-on="on")
        router-link(
          :to="{ name: 'module-page', params: { module: module.module } }"
        )
          .paid-module__text(:class="getBackgroundClass")
            h2 {{ module.module_name || module.module }}
            v-clamp.paid-module__desc(autoresize :max-lines="3") {{ module.description }}
          v-img(
            :src="module.inactive_image || placeholder"
            :lazy-src="placeholder"
            cover
          )
    span.paid-module__tooltip {{ module.description || 'Краткое описание отсутствует' }}

</template>

<script>
import VClamp from 'vue-clamp';

const placeholder = require('@/assets/images/placeholder.svg');

export default {
  name: 'PaidModule',
  components: { VClamp },
  props: {
    module: { type: Object,  required: true },
    index:  { type: Number,  required: false, default: 0 },
  },
  data() {
    return {
      placeholder,
    };
  },
  computed: {
    getBackgroundClass() {
      if (this.index === 1) return 'bg-orange';
      return 'bg-primary';
    },
  },
};
</script>

<style scoped lang="scss">

  .paid-module {
    height: 0;
    padding-top: 75%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    will-change: background-color;

    * {
      transition: .3s;
    }

    a {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      height: 100%;
      width: 100%;
    }

    .v-image {
      filter: brightness(0.8);
      position: relative;
      height: calc(100% + 40px);
      width: calc(100% + 40px);
      left: -20px;
      top: -20px;
    }

    &__text {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      transition: .3s;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        font-size: 16px;
        text-align: center;
        color: white;
        text-transform: uppercase;
        margin-bottom: 30px;
        padding: 0 10px;
      }

      .paid-module__desc {
        font-size: 15px;
        position: absolute;
        bottom: 0;
        padding: 10px 15px;
        width: 100%;

        ::v-deep span {
          color: white;
        }
      }
    }

    .bg-orange {
      background-color: rgba(#fb8c00, .9);
    }

    .bg-primary {
      background-color: rgba(#3e66a7, 0.9);
    }

    .bg-black {
      background-color: rgba(#424242, 0.8);
    }

    &:hover {
      .paid-module__text {
        background-color: rgba(#000, .2);
      }
    }
  }

  .paid-module__tooltip {
    font-size: 16px;
  }

</style>
