import { render, staticRenderFns } from "./PaidModules.vue?vue&type=template&id=71e42f73&scoped=true&lang=pug&"
import script from "./PaidModules.vue?vue&type=script&lang=js&"
export * from "./PaidModules.vue?vue&type=script&lang=js&"
import style0 from "./PaidModules.vue?vue&type=style&index=0&id=71e42f73&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e42f73",
  null
  
)

export default component.exports