<template lang="pug">
  v-container(v-if="modules.length" grid-list-md)
    h1.mb-2 Модули
    p.mb-2 Дополнительные модули АИС «ПФДО»
      br
      | Узнайте, какие преимущества Вы получите при их подключении
    .grid
      div(
        v-for="(module, idx) in modules"
        :key="idx"
        :class="`grid-elem${idx + 1}`"
      )
        paid-module(
          :module="module"
          :index="idx + 1"
        )

    v-btn.show-more.mx-auto.my-4(
      v-if="nextPage"
      ref="loadMore"
      outlined text
      color="primary"
      @click="getModules"
    ) Показать ещё

  the-loading(v-else)

</template>

<script>
import directory from '@/services/directory';

import PaidModule from '../components/PaidModule';
import TheLoading from '@/components/TheLoading';

export default {
  name: 'PaidModules',
  components: { TheLoading, PaidModule },
  data: () => ({
    modules: [],
    nextPage: 1,
  }),
  created() {
    this.getModules();
  },
  methods: {
    /**
     * Получение списка модулей
     */
    getModules() {
      const params = {
        expand: 'active_image,inactive_image',
        sort: '-id',
        page: this.nextPage,
      };
      this.$rHandler(
        directory.getModulesInfo(params),
        ({ data, headers }) => {
          this.modules = this.modules.concat(data.data);

          if (this.nextPage === 1) { setTimeout(() => this.loadMoreOnScroll()); }
          this.nextPage = +headers['x-pagination-page-count'] <= +headers['x-pagination-current-page'] ? 0 : this.nextPage += 1;
        },
      );
    },
    /**
     * Загрузка след. модулей при прокрутре страницы
     */
    loadMoreOnScroll() {
      if (this.$refs.loadMore) {
        const elem = this.$refs.loadMore.$el;
        const observer = new IntersectionObserver((entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) this.getModules();
        });

        observer.observe(elem);
      }
    },
  },
};
</script>

<style scoped lang="scss">

  .grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;

    @media (min-width: 960px) {
      grid-template-columns: 1fr 1fr 1fr;

      .grid-elem1 {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 3;

        ::v-deep h2 {
          font-size: 26px;
        }
      }
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

  }

  .show-more {
    width: calc(100% - 8px);
    border: 0;
  }

</style>
