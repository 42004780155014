<template lang="pug">
transition(name="fade")
  v-layout.ma-0.pa-0.the-loading(justify-center align-content-center v-if="loading")
    v-flex(xs6 align-self-center)
      v-progress-linear(
        indeterminate
        rounded
        height="2"
      )
</template>

<script>
export default {
  name: 'TheLoading',
  props: {
    loading: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
.the-loading {
  position: absolute;
  height: calc(100vh - 80px);
  background: $light-color;
  top: 0;
  width: 100%;
  right: 0;
  z-index: 3;
}
</style>
